
	import http from "../../../../common/services/http";
	import { defineComponent, ref, reactive, onMounted, computed } from "vue";

	export default defineComponent({
		setup(props) {
			const headers = {
				type: "Pallet/AKE Type",
				box: "Box Type",
				layer: "Layers",
				high: "High",
				boxes: "Count",
			};

			const records = ref({
				data: [],
			});

			const costs = ref([]);
			const types = ref([]);

			const record = reactive({
				pallet_type_id: 0,
				cost_id: 0,
				layer: 0,
				high: 0,
				boxes: 0,
			});

			const search = ref("");

			const processing = ref(false);
			const created = ref(false);
			const updated = ref(false);

			const type = computed(() =>
				types.value.find((t: any) => t.id == record.pallet_type_id)
			);

			function fetchRecords(page = 1) {
				processing.value = true;

				http.get(`/api/pallets?page=${page}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function fetchTypes(page = 1) {
				processing.value = true;

				http.get(`/api/pallet-types?fetch=all`).then((res) => {
					types.value = res.data;
					processing.value = false;
				});
			}

			function fetchCosts(page = 1) {
				processing.value = true;

				http.get(`/api/costs?fetch=all`).then((res) => {
					costs.value = res.data;
					processing.value = false;
				});
			}

			function getCosts(type: any) {
				return costs.value?.filter(
					(cost: any) => cost?.category?.slug == type
				);
			}

			function searchRecords(e: any) {
				processing.value = true;

				http.get(`/api/pallets?s=${search.value}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function createRecord() {
				http.post("/api/pallets", record)
					.then((res) => {
						if (res.data.success) {
							created.value = true;

							record.pallet_type_id = 0;
							record.cost_id = 0;
							record.layer = 0;
							record.high = 0;
							record.boxes = 0;
						}
					})
					.finally(() => {
						document.getElementById("hideNewForm")?.click();
						fetchRecords();
					});
			}

			function updateRecord(record: any) {
				record._method = "PUT";
				http.post(`/api/pallets/${record.id}`, {
					type: record.type,
					cost_id: record.cost_id,
					layer: record.layer,
					high: record.high,
					boxes: record.layer * record.high,
				})
					.then((res) => {
						if (res.data.success) {
							updated.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideEditForm")?.click();
						fetchRecords();
					});
			}

			function deleteRecord(id: any) {
				http.post(`/api/pallets/${id}`, { _method: "DELETE" }).then((res) => {
					fetchRecords();
				});
			}

			function setBoxes() {
				record.boxes = record.layer * record.high;
			}

			onMounted(() => {
				fetchRecords();
				fetchCosts();
				fetchTypes();
			});

			return {
				headers,
				fetchRecords,
				processing,
				records,
				createRecord,
				created,
				record,
				updateRecord,
				updated,
				deleteRecord,
				search,
				searchRecords,
				setBoxes,
				getCosts,
				types,
				type,
			};
		},
	});
